import '../public/css/rte.css';
import '../public/css/cva.css';
import '../public/fonts/fonts.css';
import '../public/css/modal.css';
import * as serviceWorker from './serviceWorker';
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js'
import '../js/elmEditor.js';
import { Elm } from './App.elm';

import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs'
import 'intro.js/introjs.css'
import introJs from 'intro.js';

const app = Elm.App.init({
  node: document.getElementById('root')
  , flags: process.env.ELM_APP_PROD
});

serviceWorker.unregister();

// port
app.ports.sendImageId.subscribe(function(id) {
  const image = document.getElementById(id);
  const save = document.getElementById('cropSave');
  // const t = document.getElementById('test');
  const posterid = id.match(/\d*/g)[0];

  const cropper = new Cropper(image, {
  viewMode: 0,
  dragMode: 'move',
  zoomable: false,
  aspectRatio: 300 / 181  });

  function uploadCropped(posterid, cropperObj) {
    save.disabled = true;
    save.innerHTML = "<img style='height:50px;' src='images/loading.gif'></img>";
    cropperObj.getCroppedCanvas().toBlob((blob) => {
      const formData = new FormData();
      

      formData.append('img', blob, id+'.png');
      formData.append('id', posterid);
      formData.append('image_type', 'full_pic');
    
      var xhr = new XMLHttpRequest();
      xhr.open('POST', process.env.ELM_APP_PROD + 'saveCropped.php', true); // /backend
      xhr.onload = function () {
        if (xhr.status === 200) {
          cropperObj.destroy();
          app.ports.receiveCrop.send(this.response);
        }
      };
      xhr.send(formData);
    });
    }
  
  

  save.onclick = function() {uploadCropped(posterid, cropper)};
  // save.onclick = () => { t.src = cropper.getCroppedCanvas().toDataURL() }
});


app.ports.startTour.subscribe(function(msg) {
  introJs().onafterchange(function(e) {
    let a = document.querySelector('.introjs-helperLayer');
    switch (e.title) {
      case 'text':
        a.style.top = parseFloat(a.style.top) - 2 + "px";
        a.style.left = parseFloat(a.style.left) + 1 + "px";
        break;
      case 'undo':
        a.style.top = parseFloat(a.style.top) - 1 + "px";
        a.style.left = parseFloat(a.style.left) - 8 + "px";
      default:
        break;
    } 
  }).start();
});